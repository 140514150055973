<template>
  <div class="SearchPage">
    <!-- 快速搜索 -->
    <searchBtnList @getApiName="getApiName" :mode="mode" />

    <!-- 搜索框 -->
    <searchInput :mode="mode" @searchBtnList="searchBtnList" />

    <!-- 列表 -->
    <tableList :mode="mode" :searchList="searchList" @showTableList="getMode" ref="tableList"/>

    <div class="bottom"  @click="goICP">
      腾云管家大数据+AI人工智能版权所有
      Copyright2017沪ICP备12044172号-2版本号V1.0.18
    </div>
  </div>
</template>
<script>
import searchBtnList from './components/searchBtnList.vue'
import searchInput from './components/searchInput.vue'
import tableList from './components/tableList.vue'
import { testing,getCity2 } from '@/api/search.js'
import { getsearchList } from '@/router/menuMain.js' 

export default {
  name: 'SearchPage',
  components: { searchBtnList, searchInput, tableList },
  data() {
    return {
      mode: '',
      modeName: '',
      searchList: [],
      searchMenuList: [],
    }
  },
  created () {
  },
  mounted() {
    this.searchMenuList = getsearchList();
    this.getMode();
    // this.mode = localStorage.getItem('mode') || 'instruments' //默认打开订单列表
    this.testingFun()
    this.getCity2Fun()
  },
  //  keep-alive缓存的页面重新的打开执行的方法
  activated() {
    this.$refs.tableList.getListFun()
    this.getMode();
    // this.mode = localStorage.getItem('mode') || 'instruments' //默认打开订单列表
    this.testingFun()
    this.getCity2Fun()
  },
  methods: {
    //  
    /* 获取并设置当前mode
        判断缓存中是否保存了
        保存了在判断权限中是否存在，存在就显示，不存在就那权限中的第一个
        没保存直接拿权限中的第一个
    */
    getMode() {
      let mode = sessionStorage.getItem('mode')
      if(mode&&this.searchMenuList.find(e => e.value == mode)){
        this.mode = mode 
      }else {
        this.mode = this.searchMenuList[0].value
        // localStorage.setItem('mode', this.mode)
        sessionStorage.setItem('mode',  this.mode )
        
      }
      
    },
    //   接受按钮搜索地址
    getApiName(value) {
      // localStorage.setItem('mode', value)
      sessionStorage.setItem('mode', value)
      this.mode = value
    },

    // 接收搜索框相关信息
    searchBtnList(value) {
      this.searchList = value
    },

    // 获取省份，城市
    getCity2Fun() {
      getCity2().then((res) => {
        let arr = []
        res.data.forEach((v) => {
          let arr2 = []
          v.city_list.forEach((e) => {
            arr2.push({
              value: e.city_id,
              label: e.city_name,
            })
          })
          arr.push({
            value: v.province_id,
            label: v.province_name,
            city_list: arr2,
          })
        })
        localStorage.setItem('province', JSON.stringify(arr)) //缓存省份
      })
    },
    // 检测项目
    testingFun() {
      testing().then((res) => {
        let arr = []
        res.data.data.forEach((v) => {
          arr.push({
            value: v.testing_id,
            label: v.testing_unique,
          })
        })
        localStorage.setItem('testing', JSON.stringify(arr)) //缓存检测项目
      })
    },
    // 当前页面跳转到当前页面
    showTableList() {
      this.mode = localStorage.getItem('mode')
    },
     // 跳转到icp备案页面
    goICP() {
       window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
    }
  },
}
</script>
<style lang="scss" scoped>
.SearchPage {
  padding: px(10) px(20) px(20) px(20);

  .bottom {
    height: px(70);
    color: #999999;
    line-height: px(70);
    text-align: center;
    font-size: px(18);
    cursor: pointer;
  }
}
</style>