<template>
  <div class="searchBtnList">
    <el-main class="hunt">
      <div class="hunt-wz">快速搜索</div>
      <div class="buttonBox">
        <div
          class="button"
          v-for="(item, index) of list"
          :key="index"
          @click="clickBtn(item.value)"
          :class="mode == item.value ? 'active' : ''"
        >
          {{ item.name }}
        </div>
      </div>
    </el-main>
  </div>
</template>
<script>
import { getsearchList } from '@/router/menuMain.js'
export default {
  props: ['mode'],
  data() {
    return {
      list: [
        {
          value: 'instruments',
          name: '仪器',
        },
        {
          value: 'samplechecks',
          name: '样本检测',
        },
        {
          value: 'kits',
          name: '试剂',
        },
        {
          value: 'qcs',
          name: '质控',
        },
        {
          value: 'calibrations',
          name: '定标',
        },
        {
          value: 'orders',
          name: '订单',
        },
        {
          value: 'dispatchs',
          name: '发货单',
        },
        {
          value: 'reports',
          name: '质检单',
        },
        {
          value: 'repairtask',
          name: '维修单',
        },
        {
          value: 'installtask',
          name: '装机单',
        },
        {
          value: 'maintaintask',
          name: '保养单',
        },
        {
          value: 'products',
          name: '产品档案',
        },
        {
          value: 'cycle_qc_data_list',
          name: '失控',
        },
        {
          value: 'recordwarnings',
          name: '告警',
        },
        {
          value: 'diarys',
          name: '日志',
        },
        {
          value: 'articleapplis',
          name: '物品申请',
        },
      ],
    }
  },
  mounted() {
    this.list = getsearchList();
  },
  methods: {
    clickBtn(value) {
      
      this.$emit('getApiName', value)
    },
  },
}
</script>
<style lang="scss" scoped>
.searchBtnList {
  background: #ffffff;
  box-shadow: 0 px(2) px(10) 0 rgba(51, 64, 79, 0.1);
  border-radius: px(5);
  .hunt-wz {
    font-weight: bold;
    color: #111111;
    margin-bottom: px(24);
  }
  .buttonBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    .button {
      width: px(86);
      height: px(36);
      background: #ffffff;
      border-radius: px(5);
      border: px(1) solid #999999;
      font-size: px(17);
      color: #666666;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: px(10);
      cursor: pointer;
      margin-bottom: px(10);
    }
    .button:nth-last-child(1) {
      margin-right: px(10);
    }
    .active {
      background: #203152;
      color: #ffffff;
    }
  }
}
</style>